import { graphql } from '~/integrations/dato/utils/graphql'
import { MediaModuleFragment } from '~/components/media/media.fragment.ts'

export const SliderModuleFragment = graphql(
  /* GraphQL */ `
    fragment SliderModuleFragment on SliderModuleRecord {
      id
      __typename
      slides {
        id
        __typename
        ... on MediaModuleRecord {
          ...MediaModuleFragment
        }
      }
      advanced
    }
  `,
  [MediaModuleFragment],
)
