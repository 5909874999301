import { graphql } from '~/integrations/dato/utils/graphql'
import { ImageModuleFragment } from '~/components/ui/image/image.fragment.ts'

export const QuoteModuleFragment = graphql(
  /* GraphQL */ `
    fragment QuoteModuleFragment on QuoteModuleRecord {
      id
      __typename
      text
      author
      authorPicture {
        ...ImageModuleFragment
      }
      advanced
    }
  `,
  [ImageModuleFragment],
)
