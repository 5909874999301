import type { TLocales } from '~/components/locales/locales.type.ts'
import { useQuery } from '../composables/useQuery.ts'
import { graphql } from '../utils/graphql'

export async function getLocales(): Promise<TLocales> {
  const localesFragments = graphql(
    /* GraphQL */ `
      query {
        _site {
          locales
        }
      }
    `,
    [],
  )
  const data = await useQuery(localesFragments, {
    key: 'locales',
  })

  return {
    locales: data.value?._site.locales ?? [],
  }
}
