import { graphql } from '~/integrations/dato/utils/graphql'
import { BlocksFragment } from '~/components/blocks/blocks.fragment.ts'

export const GridSectionFragment = graphql(
  /* GraphQL */ `
    fragment GridSectionFragment on GridSectionRecord {
      id
      __typename
      layout
      height
      area1 {
        ...BlocksFragment
      }
      area2 {
        ...BlocksFragment
      }
      area3 {
        ...BlocksFragment
      }
      area4 {
        ...BlocksFragment
      }
      container {
        width
      }
      advanced
    }
  `,
  [BlocksFragment],
)
