import { useQuery } from '../composables/useQuery.ts'
import { graphql } from '~/integrations/dato/utils/graphql'
import { TagFragment } from '~/integrations/dato/dato.ts'

export async function getMetas(): Promise<any> {
  const metasFragment = graphql(
    /* GraphQL */ `
      query RootQuery {
        _site {
          faviconMetaTags {
            ...TagFragment
          }
        }
      }
    `,
    [TagFragment],
  )
  return useQuery(metasFragment, {
    key: 'metas',
    transform(data) {
      return data._site
    },
  })
}
