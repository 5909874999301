import { graphql } from '~/integrations/dato/utils/graphql'
import { BodyModuleFragment } from '~/components/ui/body/body.fragment.ts'

export const HeroSectionFragment = graphql(
  /* GraphQL */ `
    fragment HeroSectionFragment on HeroSectionRecord {
      theme
      backgroundImage {
        url
      }
      featuredImage {
        url
      }
      body {
        ... on BodyModuleRecord {
          ...BodyModuleFragment
        }
      }
      container {
        width
      }
      advanced
    }
  `,
  [BodyModuleFragment],
)
