import { ButtonModuleFragment } from '~/components/ui/button/button.fragment.ts'
import { graphql } from '~/integrations/dato/utils/graphql'

export const BodyModuleFragment = graphql(
  /* GraphQL */ `
    fragment BodyModuleFragment on BodyModuleRecord {
      suptitle
      title
      headingLevel {
        id
        level
      }
      subtitle
      lead {
        value
      }
      text {
        value
      }
      buttons {
        ... on ButtonModuleRecord {
          id
          ...ButtonModuleFragment
        }
      }
      advanced
    }
  `,
  [ButtonModuleFragment],
)
