import { graphql } from '~/integrations/dato/utils/graphql'
import { ImageModuleFragment } from '~/components/ui/image/image.fragment.ts'

export const MediaModuleFragment = graphql(
  /* GraphQL */ `
    fragment MediaModuleFragment on MediaModuleRecord {
      image {
        ...ImageModuleFragment
      }
      video {
        url
      }
      legend
      advanced
    }
  `,
  [ImageModuleFragment],
)
