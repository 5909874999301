import { graphql } from '~/integrations/dato/utils/graphql'

export const ImageModuleFragment = graphql(/* GraphQL */ `
  fragment ImageModuleFragment on FileField {
    url
    focalPoint {
      x
      y
    }
    width
    height
  }
`)