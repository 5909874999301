import { graphql } from '~/integrations/dato/utils/graphql'
import { useQuery } from '../composables/useQuery.ts'
import { GridSectionFragment } from '~/components/grid/grid.fragment.ts'
import { BlocksFragment } from '~/components/blocks/blocks.fragment.ts'

import { type TSearchResults } from '~/components/search/search.type.ts'
import { type TSearchPage } from '~/components/pages/search/search.type.ts'

import { __deepSearch } from '@lotsof/sugar/object'
import { ImageModuleFragment } from '~/components/ui/image/image.fragment.ts'
import { TagFragment } from '../dato.ts'

function getTitleFromSeoMetas(metas: any[]): string {
  const title = metas.find((meta) => meta.tag === 'title')
  return title?.content
}
function getImageFromSeoMetas(metas: any[]): string {
  const image = metas.find((meta) => meta.attributes?.property === 'og:image')
  return image.attributes?.content
}
function getDescriptionFromSeoMetas(metas: any[]): string {
  const image = metas.find(
    (meta) => meta.attributes?.property === 'og:description',
  )
  return image.attributes?.content
}

export async function getPage(locale: string) {
  const query = graphql(
    /* GraphQL */ `
      {
        searchPage(locale: ${locale}) {
          id
          title
          sections {
            ... on GridSectionRecord {
              ...GridSectionFragment
            }
            ...BlocksFragment
          }
        }
      }
    `,
    [GridSectionFragment, BlocksFragment],
  )

  return useQuery<TSearchPage, any>(query, {
    key: `search-${locale}`,
    transform(data) {
      return data.searchPage ?? data
    },
  })
}

export async function search(
  search: string,
  locale: string,
): Promise<TSearchResults> {
  const query = graphql(
    /* GraphQL */ `
      {
        allPages(locale: ${locale}) {
          id
          title
          slug
          featuredImage {
            ...ImageModuleFragment
          }
          _seoMetaTags {
            ...TagFragment
          }
          sections {
            ... on GridSectionRecord {
              ...GridSectionFragment
            }
            ...BlocksFragment
          }
        }
      }
    `,
    [GridSectionFragment, BlocksFragment, ImageModuleFragment, TagFragment],
  )

  // query dato to get all pages
  // @TODO  add cache
  const pages = await useQuery(query, {
    key: 'search',
    cache: false,
    transform(data) {
      return data.value ?? data
    },
  })

  // @ts-ignore
  let pagesArray = pages?.allPages.map((page: any) => {
    let title = page.title ?? getTitleFromSeoMetas(page._seoMetaTags)
    let excerpt = page.excerpt ?? getDescriptionFromSeoMetas(page._seoMetaTags)
    let image = page.featuredImage ?? getImageFromSeoMetas(page._seoMetaTags)
    if (!image?.url) {
      image.url = image
    }

    return {
      id: page.id,
      title,
      excerpt,
      slug: page.slug,
      image,
      sections: page.sections,
    }
  })

  // filter pages by search
  if (search !== '*' && pagesArray?.length) {
    pagesArray = pagesArray.filter((page: any) => {
      const res = __deepSearch(page, (item: any) => {
        if (item.key.endsWith('slug') && item.key.split('.').length === 1) {
          return true
        }
        if (typeof item.value !== 'string') {
          return false
        }
        return item.value.match(new RegExp(search, 'i'))
      })

      if (res.slug && Object.keys(res).length > 1) {
        return true
      }
      return false
    })
  }

  // return the results
  return pagesArray
}
