import { graphql } from '~/integrations/dato/utils/graphql'
// import { type GraphQLTadaAPI, type TadaDocumentNode } from 'gql.tada';
import { useQuery } from '../composables/useQuery.ts'

import { type TLayout } from '~/components/layout/layout.type.ts'

export async function getLayout(name: string): Promise<TLayout> {
  const query = graphql(
    /* GraphQL */ `
      {
        layout(filter: {
          name: {
            eq: "${name}"
          }
        }) {
          id
          name
          logoHeader {
            url
          }
          logoFooter {
            url
          }
          logoPicto {
            url
          }
        }
      }
    `,
    [],
  )

  // @ts-ignore
  return useQuery(query, {
    key: `layout-${name}`,
    transform: async (data) => {
      const layoutData: any = data.layout

      if (!layoutData) return

      if (layoutData.logoHeader?.url && layoutData.logoHeader?.raw !== '') {
        const req = await fetch(layoutData.logoHeader.url),
          res = await req.text()
        layoutData.logoHeader.raw = res
      }

      if (layoutData.logoFooter?.url && layoutData.logoFooter?.raw !== '') {
        const req = await fetch(layoutData.logoFooter.url),
          res = await req.text()
        layoutData.logoFooter.raw = res
      }

      if (layoutData.logoPicto?.url && layoutData.logoPicto?.raw !== '') {
        const req = await fetch(layoutData.logoPicto.url),
          res = await req.text()
        layoutData.logoPicto.raw = res
      }

      const layout: TLayout = {
        name: layoutData.name,
        logoHeader: {
          src: layoutData.logoHeader?.url,
          raw: layoutData.logoHeader?.raw,
        },
        logoFooter: {
          src: layoutData.logoFooter?.url,
          raw: layoutData.logoFooter?.raw,
        },
        logoPicto: {
          src: layoutData.logoPicto?.url,
          raw: layoutData.logoPicto?.raw,
        },
      }

      return layout
    },
  })
}
