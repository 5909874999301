import { graphql } from '~/integrations/dato/utils/graphql'

export const LinkModuleFragment = graphql(
  /* GraphQL */ `
    fragment LinkModuleFragment on LinkModuleRecord {
      text
      link
    }
  `,
  [],
)