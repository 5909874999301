import { graphql } from '~/integrations/dato/utils/graphql'
// import { type GraphQLTadaAPI, type TadaDocumentNode } from 'gql.tada';
import { TagFragment } from '~/integrations/dato/dato.ts'
import { GridSectionFragment } from '~/components/grid/grid.fragment.ts'
import { BlocksFragment } from '~/components/blocks/blocks.fragment.ts'
import { useQuery } from '../composables/useQuery.ts'

export function getPage(slug: string, locale: string) {
  const query = graphql(
    /* GraphQL */ `
      {
        page(filter: {
          slug: {
            eq: "${slug}"
          }
        } locale: ${locale}) {
          _seoMetaTags {
            ...TagFragment
          }
          id
          title
          sections {
            ... on GridSectionRecord {
              ...GridSectionFragment
            }
            ...BlocksFragment
          }
          _allSlugLocales {
            locale
            value
          }
        }
      }
    `,
    [GridSectionFragment, BlocksFragment, TagFragment],
  )

  return useQuery(query, {
    key: `page-${slug}-${locale}`,
    transform(data) {
      return data.page ?? data
    },
  })
}
