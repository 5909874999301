import { graphql } from '~/integrations/dato/utils/graphql.ts'
import { BodyModuleFragment } from '../body/body.fragment.ts'
import { ImageModuleFragment } from '../image/image.fragment.ts'

export const CardModuleFragment = graphql(
  /* GraphQL */ `
    fragment CardModuleFragment on CardModuleRecord {
      id
      __typename
      theme
      image {
        ...ImageModuleFragment
      }
      body {
        ... on BodyModuleRecord {
          id
          ...BodyModuleFragment
        }
      }
      advanced
    }
  `,
  [BodyModuleFragment, ImageModuleFragment],
)
